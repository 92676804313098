<template>
  <NotificationGroup>
    <div class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-50">
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications, close, hovering }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            class="pointer-events-auto flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white- bg-neutral-100 border border-solid border-neutral-200 rounded-lg shadow-lg"
            v-for="notification in notifications"
            :key="notification.id"
            @mouseover="hovering(notification.id, true)"
            @mouseleave="hovering(notification.id, false)"
          >
            <template v-if="notification.type === 'success'">
              <div class="flex items-center justify-center w-12 bg-green-600">
                <CheckCircleIcon class="h-7 w-7 text-white" />
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-green-600">{{ notification.title }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </template>
            <template v-if="notification.type === 'error'">
              <div class="flex-shrink-0 flex items-center justify-center w-12 bg-red-600">
                <XCircleIcon class="h-7 w-7 text-white" />
              </div>

              <div class="flex-1 px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-red-600">{{ notification.title }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
              <div class="px-4 pt-2">
                <button
                  type="button"
                  @click="close(notification.id)"
                  class="cursor-pointer inline-flex rounded-md bg-white- text-gray-400 hover:text-gray-500 outline-none focus:outline-none"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </template>
            <template v-if="notification.type === 'fg'">
              <div class="flex-shrink-0 flex items-center justify-center w-12 bg-fg-main">
                <InformationCircleIcon class="h-7 w-7 text-white" />
              </div>

              <div class="flex-1 px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-fg-main">{{ notification.title }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
              <div class="px-4 pt-2">
                <button
                  type="button"
                  @click="close(notification.id)"
                  class="cursor-pointer inline-flex rounded-md bg-white- text-gray-400 hover:text-gray-500 outline-none focus:outline-none"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </template>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
<script setup>
  import { XMarkIcon } from "@heroicons/vue/20/solid";
  import { CheckCircleIcon, XCircleIcon, InformationCircleIcon } from "@heroicons/vue/24/solid";
</script>
